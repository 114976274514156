import React from "react";

interface DateProps {
  text: string;
}

const DateSeparator: React.FC<DateProps> = ({ text }) => {
  return (
    <div className="chatbot-important-font-body chatbot-important-my-1 chatbot-important-p-2 chatbot-important-gap-1 chatbot-important-text-sm chatbot-important-text-[#B0B4BF] chatbot-important-flex chatbot-important-items-center chatbot-important-justify-center chatbot-important-text-center chatbot-important-max-w-[400px]">
      {text}
    </div>
  );
};

export default DateSeparator;
