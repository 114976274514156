import React from "react";

interface OutMessageProps {
  text: string;
  className?: string;
}

const OutMessage: React.FC<OutMessageProps> = ({ text, className }) => {
  return (
    <div
      className={`${className} chatbot-important-font-body chatbot-important-my-2 chatbot-important-p-5 chatbot-important-gap-1 chatbot-important-bg-[#2D3C58] chatbot-important-text-sm chatbot-important-text-[#FFFFFF] chatbot-important-break-words chatbot-important-min-w-[90px] chatbot-important-max-w-[400px] chatbot-important-self-end chatbot-important-w-fit chatbot-important-rounded-[30px] chatbot-important-relative chatbot-important-rounded-br-none`}
    >
      {text}
      <div className="chatbot-important-absolute chatbot-important-w-[15px] chatbot-important-h-[16px] chatbot-important-bg-[#2D3C58] chatbot-important-self-end chatbot-important-bottom-[-13px] chatbot-important-rounded-bl-[90px] chatbot-important-right-0"></div>
    </div>
  );
};

export default OutMessage;
