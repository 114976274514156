import { MessageTypes } from "./types";

export const formatDate = (date: Date) => {
  return date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

// Function to initialize and manage messages
export const initializeMessages = (
  messages: { text: string; type: string; date?: Date }[],
  setMessages: React.Dispatch<
    React.SetStateAction<{ text: string; type: string; date?: Date }[]>
  >
) => {
  const threeDaysAgo = new Date();
  let localMessages: {
    text: string;
    type: string;
    date?: Date | undefined;
  }[] = [...messages];

  if (threeDaysAgo.getDate() <= 3) {
    if (threeDaysAgo.getMonth() === 0) {
      threeDaysAgo.setFullYear(threeDaysAgo.getFullYear() - 1);
      threeDaysAgo.setMonth(11);
    } else {
      threeDaysAgo.setMonth(threeDaysAgo.getMonth() - 1);
    }
    const lastDayOfPreviousMonth = new Date(
      threeDaysAgo.getFullYear(),
      threeDaysAgo.getMonth() + 1,
      0
    ).getDate();
    threeDaysAgo.setDate(lastDayOfPreviousMonth - (3 - threeDaysAgo.getDate()));
  } else {
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
  }

  const filteredMessages = localMessages.filter(
    (msg) => msg.date && new Date(msg.date) > threeDaysAgo
  );

  const today = new Date();
  const needsDateSeparator =
    localMessages.length === 0 ||
    !filteredMessages.some(
      (msg) =>
        msg.type === MessageTypes.DATE &&
        msg.date &&
        new Date(msg.date).toDateString() === today.toDateString()
    );

  if (needsDateSeparator) {
    const formattedDate = formatDate(today);
    localMessages = [
      ...localMessages,
      { text: formattedDate, type: MessageTypes.DATE, date: today },
    ];
  }

  const invitationText =
    "Welcome to Nebstack! I am your virtual assistant and ready to help you with any questions.";
  // Check if there are no messages in the chat (exept date)
  if (localMessages.length === 1) {
    localMessages = [
      ...localMessages,
      {
        text: invitationText,
        type: MessageTypes.ASSISTANT,
        date: new Date(),
      },
    ];
  }

  // Delete suggestions
  localMessages = localMessages.filter(
    (msg) => msg.date && msg.type !== MessageTypes.SUGGESTIONS
  );

  // Check if no messages have been sent in the past hour
  const hourAgo = new Date();
  const helpMessage =
    "Wie kann ich Ihnen heute helfen? Bitte wählen Sie eine der unten aufgeführten Kategorien aus oder geben Sie Ihre Frage ein.";
  hourAgo.setHours(hourAgo.getHours() - 1);

  const recentMessages = localMessages.filter(
    (msg) =>
      msg.date &&
      msg.type in [MessageTypes.USER, MessageTypes.ASSISTANT] &&
      msg.text !== invitationText &&
      new Date(msg.date) > hourAgo
  );

  const lastMessage = localMessages.at(localMessages.length - 1);
  if (!lastMessage || lastMessage.text !== helpMessage) {
    if (recentMessages.length === 0) {
      localMessages = [
        ...localMessages,
        {
          text: helpMessage,
          type: MessageTypes.ASSISTANT,
          date: new Date(),
        },
      ];
    }
  }

  const fiveMinutesAgo = new Date();
  fiveMinutesAgo.setMinutes(fiveMinutesAgo.getMinutes() - 5);

  const suggestionMessages = localMessages.filter(
    (msg) =>
      msg.date &&
      msg.type === MessageTypes.SUGGESTIONS &&
      new Date(msg.date) > fiveMinutesAgo
  );

  if (suggestionMessages.length === 0) {
    localMessages = [
      ...localMessages,
      { text: "", type: MessageTypes.SUGGESTIONS, date: new Date() },
    ];
  }

  localMessages = [
    ...localMessages.filter(
      (msg) =>
        msg.type !== MessageTypes.TYPING && msg.text !== "Connection lost"
    ),
  ];

  setMessages(localMessages);
};
