import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface InMessageProps {
  text: string;
  className?: string;
}

const InMessage: React.FC<InMessageProps> = ({ text, className }) => {
  return (
    <div
      className={`${className} chatbot-important-font-body chatbot-important-my-2 chatbot-important-p-5 chatbot-important-gap-1 chatbot-important-bg-[#F5F5F5] chatbot-important-text-sm chatbot-important-text-[#2D3C58] chatbot-important-flex chatbot-important-items-center chatbot-important-min-w-[90px] chatbot-important-max-w-[400px] chatbot-important-rounded-[30px] chatbot-important-w-fit chatbot-important-relative chatbot-important-rounded-bl-none`}
    >
      <Markdown
        components={{
          ol: (props) => (
            <ol {...props} className="chatbot-important-space-y-5">
              {props.children}
            </ol>
          ),
        }}
        className="chatbot-important-flex-col chatbot-important-space-y-5"
        rehypePlugins={[rehypeRaw]}
      >
        {text}
      </Markdown>
      <div className="chatbot-important-absolute chatbot-important-w-[15px] chatbot-important-h-[16px] chatbot-important-bg-[#F5F5F5] chatbot-important-self-start chatbot-important-bottom-[-13px] chatbot-important-rounded-br-[90px] chatbot-important-left-0"></div>
    </div>
  );
};

export default InMessage;
