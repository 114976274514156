import React from "react";
import ReactDOM from "react-dom";
import ChatWidget from "ChatWidget";
import "./index.css";

const root = document.getElementById("chat-root"); // Ensure there's an element with id 'root'
if (root) {
  ReactDOM.render(<ChatWidget />, root);
} else {
  console.error('Element with id "root" not found.');
}
