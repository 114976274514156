import React from "react";

interface TypingMessageProps {
  className?: string;
}

const TypingMessage: React.FC<TypingMessageProps> = ({ className }) => {
  return (
    <div
      className={`${className} chatbot-important-my-2 chatbot-important-p-5 chatbot-important-gap-1 chatbot-important-bg-[#F5F5F5] chatbot-important-text-sm chatbot-important-text-[#2D3C58] chatbot-important-flex chatbot-important-items-center chatbot-important-max-w-[400px] chatbot-important-rounded-[30px] chatbot-important-w-fit chatbot-important-relative chatbot-important-rounded-bl-none`}
    >
      <div className="chatbot-important-flex chatbot-important-space-x-1">
        <div className="dot chatbot-important-w-2 chatbot-important-h-2 chatbot-important-bg-gray-500 chatbot-important-rounded-full chatbot-important-animate-[pulse_2s_infinite_100ms]"></div>
        <div className="dot chatbot-important-w-2 chatbot-important-h-2 chatbot-important-bg-gray-500 chatbot-important-rounded-full chatbot-important-animate-[pulse_2s_infinite_200ms]"></div>
        <div className="dot chatbot-important-w-2 chatbot-important-h-2 chatbot-important-bg-gray-500 chatbot-important-rounded-full chatbot-important-animate-[pulse_2s_infinite_300ms]"></div>
      </div>
    </div>
  );
};

export default TypingMessage;
