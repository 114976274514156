import React, { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { SHA256, enc } from "crypto-js";

import InMessage from "./messages/in-message";
import OutMessage from "./messages/out-message";
import {
  SuggestionsMessage,
  SelectedSuggestionMessage,
} from "./messages/suggestions";
import DateSeparator from "./messages/date-separator";

import * as Dialog from "@radix-ui/react-dialog";
import { initializeMessages } from "./chat-utils";
import { MessageTypes } from "./types";

import background from "./images/background.png";
import logo from "./images/logo.svg";
import xmark from "./images/x-mark.svg";
import chat from "./images/chat.svg";
import send from "./images/send.svg";
import Skeleton from "skeletons/skeleton";
import TypingMessage from "skeletons/assistant-typing";

const SECRET_KEY = "neb-stack-6e6c5ef2-2037-4c43-9a40-a32fbe1bcd63";
const DEFAULT_WS_URL = "wss://chatbot.nebstack.de/ws";
const MAX_MESSAGES = 80;

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [authQuery, setAuthQuery] = useState("");
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [userId, setUserId] = useState<number | null>(null);
  const [messages, setMessages] = useLocalStorage<
    { text: string; type: string; date?: Date }[]
  >("chatMessages", []);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(true);

  const listItemsRef = useRef<HTMLDivElement | null>(null);

  // Set the auth query string based on credentials or userId
  useEffect(() => {
    if (userCredentials.email) {
      setAuthQuery(
        `?email=${userCredentials.email}&password=${userCredentials.password}`
      );
    } else if (userId !== null) {
      const hexId = userId.toString(16);
      const hashedSecret = SHA256(`${hexId}.${SECRET_KEY}`).toString(enc.Hex);
      setAuthQuery(`?apikey=${hashedSecret}`);
    }
  }, [userId, userCredentials]);

  // Initialize messages
  useEffect(() => {
    initializeMessages(messages, setMessages);
  }, []);

  // Scroll to the last message when new messages arrive
  useEffect(() => {
    scrollToLastMessage("smooth");
  }, [messages]);

  // Open WebSocket connection when authQuery changes
  useEffect(() => {
    if (webSocket) {
      webSocket.close();
    }
    if (authQuery) connectWebSocket();
  }, [authQuery]);

  // Scroll to last message when the widget is opened
  useEffect(() => {
    if (isOpen) setTimeout(() => scrollToLastMessage("instant"), 0);
  }, [isOpen]);

  const setUser = (email: string, password: string) => {
    setUserCredentials({ email, password });
  };

  const startWS = () => {
    connectWebSocket();
  };

  useEffect(() => {
    setMessages((prevMessages) => {
      let updatedMessages = [...prevMessages];

      // Calculate how many messages need to be removed
      const excessMessagesCount = updatedMessages.length - MAX_MESSAGES;

      // If the count exceeds the maximum allowed, remove the excess messages from the start
      if (excessMessagesCount > 0) {
        updatedMessages = updatedMessages.slice(excessMessagesCount);
      }

      return updatedMessages;
    });
  }, []);

  const scrollToLastMessage = (behavior: ScrollBehavior) => {
    const lastItem = listItemsRef.current?.lastElementChild;
    lastItem?.scrollIntoView({ behavior, block: "end" });
  };

  const connectWebSocket = () => {
    const ws = new WebSocket(`${DEFAULT_WS_URL}${authQuery}`);
    ws.onopen = () => setIsWaitingForResponse(false);
    ws.onmessage = (event) => {
      setMessages((prevMessages) => {
        const filteredMessages = prevMessages.filter(
          (msg) => msg.type !== MessageTypes.TYPING
        );
        return [
          ...filteredMessages,
          { text: event.data, type: MessageTypes.ASSISTANT, date: new Date() },
        ];
      });
      setIsWaitingForResponse(false);
    };
    ws.onclose = (event) => {
      if (!event.wasClean) {
        setMessages((prevMessages) => {
          const filteredMessages = prevMessages.filter(
            (msg) => msg.type !== MessageTypes.TYPING
          );
          return [
            ...filteredMessages,
            {
              text: "Connection lost",
              type: MessageTypes.ASSISTANT,
              date: new Date(),
            },
          ];
        });
      } else {
        setMessages((prevMessages) => {
          const filteredMessages = prevMessages.filter(
            (msg) => msg.type !== MessageTypes.TYPING
          );
          return [...filteredMessages];
        });
      }
      setIsWaitingForResponse(true);
    };
    setWebSocket(ws);
  };

  const handleSendMessage = () => {
    if (!input.trim()) return;

    const newMessage = {
      text: input,
      type: MessageTypes.USER,
      date: new Date(),
    };
    setMessages((prevMessages) => [
      ...prevMessages,
      newMessage,
      { text: "", type: MessageTypes.TYPING, date: new Date() },
    ]);
    webSocket?.send(input);
    setInput("");
    setIsWaitingForResponse(true);
  };

  const selectSuggestion = (index: number, messageText: string) => {
    const filteredMessages = messages.filter((_, i) => i !== index);
    setMessages([
      ...filteredMessages,
      { text: messageText, type: MessageTypes.SUGGESTION, date: new Date() },
      { text: "", type: MessageTypes.TYPING, date: new Date() },
    ]);
    webSocket?.send(messageText);
    setIsWaitingForResponse(true);
  };

  (window as any).NebstackChatbot = {
    startWithUserCredentials: setUser,
    startWithUserId: setUserId,
    startWithoutUser: startWS,
  };

  const renderMessages = () => {
    return messages.map((msg, index) => {
      switch (msg.type) {
        case MessageTypes.USER:
          return <OutMessage key={index} text={msg.text} />;
        case MessageTypes.TYPING:
          return <TypingMessage key={index} />;
        case MessageTypes.ASSISTANT:
          return <InMessage key={index} text={msg.text} />;
        case MessageTypes.SUGGESTIONS:
          return (
            <SuggestionsMessage
              key={index}
              index={index}
              onSelect={({ index, messageText }) =>
                selectSuggestion(index, messageText)
              }
            />
          );
        case MessageTypes.SUGGESTION:
          return <SelectedSuggestionMessage key={index} text={msg.text} />;
        case MessageTypes.DATE:
          return <DateSeparator key={index} text={msg.text} />;
        default:
          return null;
      }
    });
  };

  return (
    <div className="chat-assistant-root chatbot-important-fixed chatbot-important-p-4 chatbot-important-z-10">
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <Dialog.Trigger asChild>
          {!isOpen && (
            <button className="chat-assistant-button chatbot-important-bg-transparent chatbot-important-p-2 chatbot-important-rounded-full">
              <img
                src={chat}
                alt="Chat"
                className="chatbot-important-w-12 chatbot-important-h-12 "
              />
            </button>
          )}
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Content className="chat-assistant-content chatbot-important-fixed chatbot-important-bottom-4 chatbot-important-right-4 chatbot-important-w-90 chatbot-important-bg-white chatbot-important-rounded-t-[27px] chatbot-important-rounded-b-lg chatbot-important-shadow-lg chatbot-important-z-[10000]">
            <Dialog.Title className="chatbot-important-relative chatbot-important-h-[72px] chatbot-important-text-lg chatbot-important-font-medium chatbot-important-text-black">
              <img
                src={background}
                alt="background"
                className="chatbot-important-absolute chatbot-important-top-0 chatbot-important-left-0 chatbot-important-w-full chatbot-important-h-[72px] chatbot-important-rounded-t-[25px] chatbot-important-object-cover"
              />
              <div className="chatbot-important-flex chatbot-important-justify-between chatbot-important-items-center chatbot-important-p-[20px]">
                <img
                  src={logo}
                  alt="logo"
                  className="chatbot-important-h-[36px] chatbot-important-z-[1]"
                />
                <img
                  src={xmark}
                  alt="Close"
                  className="chatbot-important-w-[12px] chatbot-important-cursor-pointer chatbot-important-z-[1]"
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </Dialog.Title>

            <Dialog.Description>
              <div className="chatbot-important-flex chatbot-important-flex-col chatbot-important-w-[400px] chatbot-important-h-[488px]">
                <div
                  ref={listItemsRef}
                  className="chatbot-important-flex-1 chatbot-important-gap-1 chatbot-important-p-2 chatbot-important-overflow-y-auto custom-scrollbar chatbot-important-flex chatbot-important-flex-col"
                >
                  {renderMessages()}
                </div>
                <div className="chatbot-important-flex chatbot-important-p-2 chatbot-important-border-t chatbot-important-text-black">
                  <input
                    type="text"
                    className="chatbot-important-font-body chatbot-important-m-0 chatbot-important-flex-1 chatbot-important-p-2 chatbot-important-border-solid chatbot-important-border chatbot-important-border-1 chatbot-important-border-[#DBDBDB] chatbot-important-rounded-[20px] focus:chatbot-important-outline-none"
                    value={input}
                    placeholder="Geben Sie eine Nachricht ein"
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                    disabled={isWaitingForResponse || !webSocket}
                  />
                  <img
                    src={send}
                    alt="Send"
                    className={`chatbot-important-ml-2 chatbot-important-cursor-pointer ${
                      isWaitingForResponse || !webSocket ? "opacity-50" : ""
                    }`}
                    onClick={handleSendMessage}
                  />
                </div>
              </div>
            </Dialog.Description>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default ChatWidget;
