import React from "react";

import person from "../images/person.png";
import screen from "../images/screen.png";
import card from "../images/card.png";
import tools from "../images/tools.png";
import questionmark from "../images/questionmark.png";
import box from "../images/package.png";

const items = [
  {
    icon: <img width={20} src={screen} alt="Screen" className=""></img>,
    text: "Status des Servers",
  },
  {
    icon: <img width={20} src={person} alt="Person" className=""></img>,
    text: "Meine Kundendaten",
  },
  {
    icon: <img width={20} src={card} alt="Card" className=""></img>,
    text: "Meine Kontodaten",
  },
  {
    icon: <img width={20} src={tools} alt="Tools" className=""></img>,
    text: "Ich bruache Hilfe",
  },
  {
    icon: (
      <img width={20} src={questionmark} alt="Questionmark" className=""></img>
    ),
    text: "FAQ",
  },
  {
    icon: <img width={20} src={box} alt="Box" className=""></img>,
    text: "Unsere Produkte",
  },
];

interface SuggestionsMessageProps {
  index: number;
  onSelect: ({}: {
    index: number;
    icon: JSX.Element;
    messageText: string;
  }) => void;
}

const SuggestionsMessage: React.FC<SuggestionsMessageProps> = ({
  onSelect,
  index: messageIndex,
}) => {
  return (
    <div className="chatbot-important-flex chatbot-important-justify-end">
      <div className="chatbot-important-my-2 chatbot-important-gap-[6px] chatbot-important-flex-wrap chatbot-important-flex chatbot-important-justify-end chatbot-important-items-end chatbot-important-text-sm chatbot-important-text-center chatbot-important-text-[#2D3C58] chatbot-important-max-w-[330px]">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className="chatbot-important-font-body chatbot-important-font-[600] chatbot-important-flex chatbot-important-gap-1 hover:chatbot-important-bg-[#ff730050] chatbot-important-bg-[#FF71001A] chatbot-important-rounded-[12px] chatbot-important-border-solid chatbot-important-border-[1px] chatbot-important-border-[#FF710066] chatbot-important-p-2 chatbot-important-cursor-pointer"
              onClick={() =>
                onSelect({
                  index: messageIndex,
                  icon: item.icon,
                  messageText: item.text,
                })
              }
            >
              {item.icon}
              <span>{item.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface SelectedSuggestionMessageProps {
  text: string;
}

const SelectedSuggestionMessage: React.FC<SelectedSuggestionMessageProps> = ({
  text,
}) => {
  return (
    <div className="chatbot-important-font-body chatbot-important-my-2 chatbot-important-p-4 chatbot-important-flex chatbot-important-gap-1 chatbot-important-bg-[#2D3C58] chatbot-important-text-sm chatbot-important-text-[#FFFFFF] chatbot-important-break-words chatbot-important-self-end chatbot-important-max-w-[400px] chatbot-important-rounded-[30px]">
      {items.filter((x) => x.text === text)[0]?.icon}
      <span>{text}</span>
    </div>
  );
};

export { SelectedSuggestionMessage, SuggestionsMessage };
